
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import StepList from "@/components/scope/step-list.vue";
import {
  emptyToLine,
  splitThousands,
  numDateToDate,
  genderFormat,
  enterWeComFormat, downloadAppFormat, followOfficialAccountFormat, timeFormat
} from "@/utils/common";
import dayjs from "dayjs";
import { Table } from "@/utils/decorator";
import Template from "@/views/dashboard/template.vue";

// import t from "@common/src/i18n/t";

const customer = namespace("customer");
const marketing = namespace("marketing");
const vehicleCheck = namespace("vehicleCheck");
const maintenance = namespace("maintenance");
const coupon = namespace("coupon");
const finance = namespace("finance");
const userMember = namespace("userMember");

@Component({
  methods: { timeFormat },
  components: { Template, StepList }
})
@Table("loadListData")
export default class CustomerView extends Vue {
  dayjs = dayjs;
  @customer.Action getNewCustomerDetail;
  @customer.Action getUserExpensesRecord;
  @customer.Action getBillList;
  @vehicleCheck.Action getCheckOrderList;
  @vehicleCheck.Action getCheckOrderDetail;
  @maintenance.Action getMaintenanceList;
  @maintenance.Action getMaintenanceReportList;
  @maintenance.Action getMaintenanceReportItems;
  @marketing.Action queryCardList;
  @coupon.Action queryUserCouponList;
  @finance.Action getFinanceWithdrawalList;
  @userMember.Action getMemberLevelLogList;
  @userMember.Action getUserIntegralAccountInfo;
  @userMember.Action getUserIntegralAccountLog;
  splitThousands = splitThousands;
  numDateToDate = numDateToDate;
  emptyToLine = emptyToLine;
  genderFormat = genderFormat;
  enterWeComFormat = enterWeComFormat;
  downloadAppFormat = downloadAppFormat;
  followOfficialAccountFormat = followOfficialAccountFormat;


  defaultAvatar: string = "https://taian-oss.dasmaster.com/wechat/default_head_admin_table.png";
  detail: any = {};
  userId: any = "";
  vehicleCode: any = "";

  selectedVehicle: any = {};
  expenseRecordList: any[] = []; //消费记录
  expenseTotalAmount: string = ""; // 消费总额
  expenseRecordListTotal: number = 0; // 消费记录总条数
  recordPageNum: number = 1;

  //检测报告
  checkOrderList: any[] = [];
  checkOrderDetail:any = {};
  checkOrderDetailDialogVisible:boolean = false;

  //保养纪录
  maintenancePageSize = 5;
  maintenanceRecordTotal=0;
  maintenanceRecord: any=[];

  //养车报告
  maintenanceReports: any=[]
  maintenanceReportItemDialogVisible = false;
  maintenanceReportItems: any=[]

  drawer: boolean = false;
  cardList: any[] = [];

  prepaidCardsList: any[] = [];

  tabIndex: any = '0';

  cardCurrentPage: number = 1;
  cardTotal: number = 0;
  cardPageSize: number = 10;


  prepaidCardsCurrentPage: number = 1;
  prepaidCardsTotal: number = 0;
  prepaidCardsPageSize: number = 10;

  //优惠券
  couponList: any[] = [];
  couponCurrentPage: number = 1;
  couponTotal: number = 0;
  couponPageSize: number = 10;
  reportItemsLoading:boolean =  false;


  checkExternalUrl:string = ''


  rewardDrawer:boolean = false

  billList: any[] = [];


  billCurrentPage: number = 1;
  billTotal: number = 0;
  billPageSize: number = 10;

  withdrawalList: any[] = [];
  withdrawalCurrentPage: number = 1;
  withdrawalTotal: number = 0;
  withdrawalPageSize: number = 10;

  //会员记录
  memberLevelLogDrawer:boolean = false;
  memberLevelLogList: any[] = [];
  memberLevelLogCurrentPage: number = 1;
  memberLevelLogTotal: number = 0;
  memberLevelLogPageSize: number = 10;

  //积分账户信息
  userIntegralAccountInfo: any = {};
  userIntegralAInstructionType = null;
  userIntegralAccountLogDrawer:boolean = false;
  userIntegralAccountLogList: any[] = [];
  userIntegralAccountCurrentPage: number = 1;
  userIntegralAccountLogTotal: number = 0;
  userIntegralAccountPageSize: number = 10;

  created() {
    this.initUserDetail();
  }

  initUserDetail() {
    this.userId = this.$route.params.id;
    this.getNewCustomerDetail(this.userId).then(data => {
      this.detail = data.data;
      if(this.detail.vehicleList.length > 0){
        this.selectedVehicle = this.detail.vehicleList[0];
      }
      this.queryExpenseList();
      //查询用户积分账户信息
      this.queryUserIntegralAccountInfo();
    });
  }

  queryExpenseList() {
    let plateCode = this.selectedVehicle.plateCode
    this.getUserExpensesRecord({
      pageNum: this.recordPageNum,
      pageSize: 3,
      plateCode: plateCode,
      mobile: this.detail.phone
    }).then(data => {
      this.expenseRecordList.push(...data.data.list);
      this.expenseTotalAmount = data.data.totalAmount;
      this.expenseRecordListTotal = data.data.count;
    });
  }

  queryUserIntegralAccountInfo() {
    let phone = this.detail.phone;
    this.getUserIntegralAccountInfo({
      phone: phone
    }).then(data => {
      this.userIntegralAccountInfo = data.data;
    });
  }


  handleTabChange(tab){
    this.tabIndex = tab.index
    this.expenseRecordList = []
    this.checkOrderList = []
    this.getMaintenanceList = []
    this.maintenanceReports= []
    this.recordPageNum = 1
    switch (tab.index){
      case '0':
        this.queryExpenseList()
        break;
      case '1':
        this.queryCheckOrderList()
        break;
      case '2':
        this.queryMaintenanceList(1)
        break;
      case '3':
        this.loadMaintenanceReports()
        break;
      default:
    }
  }
  handleRecordMore() {
    this.recordPageNum++;
    this.queryExpenseList();
  }

  handleViewOther(item) {
    this.selectedVehicle = item;

    this.expenseRecordList = []
    this.checkOrderList = []
    this.getMaintenanceList = []
    this.maintenanceReports= []

    this.recordPageNum = 1
    switch (this.tabIndex){
      case '0':
        this.queryExpenseList()
        break;
      case '1':
        this.queryCheckOrderList()
        break;
      case '2':
        this.queryMaintenanceList(1)
        break;
      case '3':
        this.loadMaintenanceReports()
        break;
      default:
    }
  }

  handleLinkView(data) {
    this.$router.push(`/service/view/${data.orderNo}?tenantCode=${data.tenantCode}`);
  }

  handleViewCheckDetail( checkOrderNo ){
    // this.checkOrderDetail = {};
    // this.queryCheckOrderDetail(checkOrderNo);
    this.checkOrderDetailDialogVisible = true;
    console.info(`${process.env.VUE_APP_FACTORY_API}`)
    this.checkExternalUrl = `${process.env.VUE_APP_CHECK_H5_SITE}?orderNo=`+checkOrderNo;
  }


  queryCheckOrderDetail(checkOrderNo){
    this.getCheckOrderDetail({
      checkOrderNo: checkOrderNo
    }).then(data => {
      let checkOrderDetailsData = data.data.checkOrderDetails;
      for (let index in checkOrderDetailsData) {
        let checkOrderDetail = checkOrderDetailsData[index];
        checkOrderDetail.subItems = [];
        let classSubItems = checkOrderDetail.classSubItems;
        for (let classSubIndex in classSubItems) {
          let classSubItem = classSubItems[classSubIndex];
          let subItems = classSubItem.subItems;
          Object.assign(checkOrderDetail.subItems, subItems)

        }
      }
      this.checkOrderDetail = data.data;
      this.checkOrderDetail.checkOrderDetails = checkOrderDetailsData;
    });
  }

  queryCheckOrderList() {
    let plateCode = this.selectedVehicle.plateCode
    this.getCheckOrderList({
      mobile: this.detail.phone,
      plateCode: plateCode
    }).then(data => {
      this.checkOrderList = data.data;
    });
  }

  queryMaintenanceList(pageNum){
    let plateCode = this.selectedVehicle.plateCode
    this.getMaintenanceList({
      current: pageNum,
      size: this.maintenancePageSize,
      userPhone: this.detail.phone,
      userLicensePlate: plateCode
    }).then(data => {
      this.maintenanceRecord = data.data.list;
      this.maintenanceRecordTotal = data.data.total;
    });
  }



  loadMaintenanceReports(){
    let plateCode = this.selectedVehicle.plateCode

    this.getMaintenanceReportList({
      userPhone: this.detail.phone,
      userLicensePlate: plateCode
    }).then(data => {
      this.maintenanceReports = data.data;
    });
  }

  queryMaintenanceReports(reportNo){
    this.reportItemsLoading = true
    this.getMaintenanceReportItems({
      reportNo: reportNo
    }).then(data => {
      this.maintenanceReportItems = data.data;
      this.reportItemsLoading = false;
    });

  }

  handleViewReportItems(reportNo){
    this.maintenanceReportItems = [];
    this.queryMaintenanceReports(reportNo);
    this.maintenanceReportItemDialogVisible = true;
  }

  cardHandle(){
    this.drawer =true
    this.loadCardListData()
  }

  memberLevelHandle(){
    this.memberLevelLogDrawer =true
    this.loadMemberLevelLogData();
  }

  rewardHandle(){
    this.rewardDrawer = true
    this.loadBillListData()
  }
  loadBillListData() {
    this.getBillList({
      mobile: this.detail.phone,
      orderItems: this.mix_sortParams,
      pageNum: this.billCurrentPage,
      pageSize: this.billPageSize,
    }).then(data => {
      this.billTotal = data.data.total
      this.billList = data.data.list
    });
  }

  billHandleSizeChange(size){
    this.billPageSize = size;
    this.loadBillListData()
  }

  billHandlePageChange(pageNo){
    this.billCurrentPage = pageNo
    this.loadBillListData()
  }
  handleRewardClose(){
    this.rewardDrawer = false
    this.billList = []
  }

  handleClose(){
    this.drawer =false
    this.cardList = []
  }

  handleMemberLevelLogClose(){
    this.memberLevelLogDrawer = false
    this.memberLevelLogList = []
  }

  memberLevelLogHandlePageChange(pageNo){
    this.memberLevelLogCurrentPage = pageNo
    this.loadMemberLevelLogData()
  }

  memberLevelLogHandleSizeChange(size){
    this.memberLevelLogPageSize = size;
    this.loadMemberLevelLogData()
  }


  userIntegralLogHandlePageChange(pageNo){
    this.userIntegralAccountCurrentPage = pageNo
    this.loadUserIntegralLogData()
  }

  userIntegralLogHandleSizeChange(size){
    this.userIntegralAccountPageSize = size;
    this.loadUserIntegralLogData()
  }

  loadCardListData() {
    this.queryCardList({
      mobile: this.detail.phone,
      orderItems: this.mix_sortParams,
      pageNum: this.cardCurrentPage,
      pageSize: this.cardPageSize,
      cardType: 1
    }).then(data => {
       this.cardTotal = data.data.total
       this.cardList = data.data.list
    });
  }

  loadMemberLevelLogData() {
    this.getMemberLevelLogList({
      phone: this.detail.phone,
      current: this.memberLevelLogCurrentPage,
      size: this.memberLevelLogPageSize,
    }).then(data => {
      this.memberLevelLogTotal = data.data.total;
      this.memberLevelLogList = data.data.list;
    });
  }

  loadUserIntegralLogData() {
    this.getUserIntegralAccountLog({
      phone: this.detail.phone,
      accountNo: this.userIntegralAccountInfo.accountNo,
      instructionType: this.userIntegralAInstructionType,
      current: this.userIntegralAccountCurrentPage,
      size: this.userIntegralAccountPageSize
    }).then(data => {
      this.userIntegralAccountLogTotal = data.data.total;
      this.userIntegralAccountLogList = data.data.records;
    });
  }

  userIntegralInstructionType(type) {
    this.userIntegralAInstructionType = type;
    this.loadUserIntegralLogData();
  }

  loadPrepaidCardsListData() {
    this.queryCardList({
      mobile: this.detail.phone,
      orderItems: this.mix_sortParams,
      pageNum: this.prepaidCardsCurrentPage,
      pageSize: this.prepaidCardsPageSize,
      cardType: 2
    }).then(data => {
      this.prepaidCardsList = data.data.list
      this.prepaidCardsTotal = data.data.total
    });
  }

  couponTabClick(tab){
    switch (tab.index){
      case '0':
        this.loadCardListData()
        break;
      case '1':
        this.loadPrepaidCardsListData()
        break;
      case '2':
        this.loadListCouponData()
        break;
      default:
    }
  }
  cardHandleSizeChange(size){
    this.cardPageSize = size;
    this.loadCardListData()
  }

  cardHandlePageChange(pageNo){
    this.cardCurrentPage = pageNo
    this.loadCardListData()
  }


  prepaidCardsHandleSizeChange(size){
    this.prepaidCardsPageSize = size;
    this.loadPrepaidCardsListData()
  }

  prepaidCardsHandlePageChange(pageNo){
    this.prepaidCardsCurrentPage = pageNo;
    this.loadPrepaidCardsListData()
  }

  loadListCouponData() {
    this.queryUserCouponList({
      pageNum: this.couponCurrentPage,
      pageSize: this.couponPageSize,
      customerMobile: this.detail.phone
    }).then(data => {
      this.couponList = data.data.list;
      this.couponTotal = data.data.total;
    });
  }

  couponHandleSizeChange(size){
    this.couponPageSize = size;
    this.loadListCouponData()
  }

  couponHandlePageChange(pageNo){
    this.couponCurrentPage = pageNo;
    this.loadListCouponData()
  }
  loadWithdrawalListData() {
    return this.getFinanceWithdrawalList({
      mobile: this.detail.phone,
      pageSize: this.withdrawalPageSize,
      pageNum: this.withdrawalCurrentPage
    }).then(data => {
      this.withdrawalList = data.data.list;
      this.withdrawalTotal = data.data.total;
    });
  }
  rewardTabChange(tab){
    switch (tab.index){
      case '0':
        this.loadBillListData()
        break;
      case '1':
        this.loadWithdrawalListData()
        break;
      default:
    }
  }
  withdrawalHandlePageChange(size){
    this.withdrawalPageSize = size;
    this.loadWithdrawalListData()
  }

  withdrawalHandleSizeChange(pageNo){
    this.withdrawalCurrentPage = pageNo;
    this.loadWithdrawalListData()
  }

  memberTabChange(tab){
    switch (tab.index){
      case '0':
        this.loadMemberLevelLogData()
        break;
      case '1':
        this.loadUserIntegralLogData()
        break;
      default:
    }
  }
}
